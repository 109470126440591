<template>
  <div class="video-wrapper">
      <video ref="horseVid" width="320" height="570" autoplay muted loop>
          <source :src="require('@/assets/horse-no-text.mp4')" type="video/mp4">
      </video>
      <div ref="textDiv" class="wish-text-container">
        <div class="slide-right giant-text text-outlined">Auguri</div>
        <div class="slide-down giant-text text-outlined">Babi</div>
        <span class="slide-up bento-text text-outlined">Per il tuo compleanno, oltre a questo bellissimo messaggio di auguri, 
          ti regaliamo una lezione di equitazione.</span>
      </div>
  </div>
  <div class="bento-box">
    <div class="bento-text">
      Nel bellissimo ranch Pergola Bella
      <img :src="require('@/assets/pergola-bella-ranch.jpg')" alt="Pergola Bella Ranch">
      <HorseButton />
    </div>

  </div>
  <div class="bento-box"  v-element-visibility="onElementVisibility">
    <div class="bento-text">
      Cosa ti servirà?
    </div>
    <div id="appear-on-scroll" class="image-wrapper">
      <div id="scarponi-image" class="image-container scarponi-img">
        <img :src="require('@/assets/scarponi.jpg')" alt="Pergola Bella Ranch">
        Scarponi
      </div>
      <div id="frusta-image" class="image-container frusta-img">
        <img :src="require('@/assets/frusta.jpg')" alt="Pergola Bella Ranch">
        Frusta
      </div>

      <div id="cavallo-image" class="image-container cavallo-img">
        <img :src="require('@/assets/cavallo.jpg')" alt="Pergola Bella Ranch">
        Cavallo
      </div>


    </div>
      <!--<div v-for="(item, index) in items" :key="index" :class="['bento-text', item.class]">
        {{ item.text }}
      </div>
    -->

  </div>
</template>

<script>
import { vElementVisibility } from '@vueuse/components';
import { ref } from 'vue';
import HorseButton from './HorseButton.vue';


export default {
  name: 'HelloWorld',
  components:{
    HorseButton
  },
  directives: {
    elementVisibility: vElementVisibility
  },
  setup() {
    const isVisible = ref(false);

    function onElementVisibility(state) {
      console.log(state);
      if(state){
      const scarpImg=document.getElementById('scarponi-image');
      scarpImg.classList.add('slide-right-fast');
      const frustaImg=document.getElementById('frusta-image');
      frustaImg.classList.add('slide-up-fast');
      const cavalloImg=document.getElementById('cavallo-image');
      cavalloImg.classList.add('slide-left-fast');
      }

    }


    return {
      isVisible,
      onElementVisibility
    };
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root{
  --font-primary-color:#000000;
  --font-secondary-color:#d6d6d6;
}


.image-wrapper{
  display:flex;
}

.image-container{
  border:thin solid black;
  border-radius:16px;
  margin: 0 1rem;

  img{
    margin:3px!important;
  }
}

.scarponi-img{
  transform: translateX(-100vh);
}
.frusta-img{
  transform: translateY(+100vh);
}
.cavallo-img{
  transform: translateX(+100vh);
}

.video-wrapper{
  position:relative;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  height: 100%;
  max-width: 450px;

  video{
    width: 100%;
    height: auto;
    display: block;
  }
}

.bento-box{
  border: thin solid black;
  border-radius: 16px;
  width: calc(100% - 2rem);
  margin: 1rem 1rem;
  padding: 2rem 1rem;
  background-color: #f4ece270;
  color: black;
  max-width: 450px;

  img{
    width:calc(100% - 2rem);
    height: auto;
    margin:1rem;
  }

}

.bento-text{
  font-size: 2rem;
  font-weight: 300;
}

.wish-text-container{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position:absolute;
  top: 5px;
  >*{
    margin: 0.5rem;
  }
}

.giant-text{
  font-size: 4rem;
  color: var(--font-primary-color);
  font-weight: 500;
}

.text-outlined{
  color: var(--font-primary-color);
  text-shadow:
    -0.3px -0.3px 0 var(--font-secondary-color),
     0   -0.3px 0 var(--font-secondary-color),
     0.3px -0.3px 0 var(--font-secondary-color),
     0.3px  0   0 var(--font-secondary-color),
     0.3px  0.3px 0 var(--font-secondary-color),
     0    0.3px 0 var(--font-secondary-color),
    -0.3px  0.3px 0 var(--font-secondary-color),
    -0.3px  0   0 var(--font-secondary-color);

}

@keyframes slide-right {
  0% {
    transform: translateX(-100vh);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(+100vh);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(+100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-right {
  display: block;
  animation: slide-right 2s ease-out forwards;
}



.slide-down {
  display: block;
  animation: slide-down 2s ease-out forwards;
}
.slide-up {
  display: block;
  animation: slide-up 2s ease-out forwards;
}
.slide-right-fast {
  display: block;
  animation: slide-right 1s ease-out forwards;
}
.slide-left-fast {
  display: block;
  animation: slide-left 1s ease-out forwards;
}
.slide-up-fast {
  display: block;
  animation: slide-up 1s ease-out forwards;
}

</style>


<!--

   // const vid = this.$refs.horseVid;
   // const textDiv = this.$refs.textDiv;
   // vid.onended = () => {
   //   vid.remove();
    //  // Create the first div
    //  const auguriDiv = document.createElement('div');
    //  auguriDiv.classList.add('slide-right', 'giant-text');
    //  auguriDiv.innerText = 'Auguri';
//
    //  // Create the second div
    //  const babiDiv = document.createElement('div');
    //  babiDiv.classList.add('slide-down', 'giant-text');
    //  babiDiv.innerText = 'Babi';
//
    //  // Create the bento box
    //  const bentoBox = document.createElement('div');
    //  bentoBox.classList.add('slide-up', 'bento-box');
//
    //  const bentoText= document.createElement('span');
    //  bentoText.classList.add('bento-text');
    //  bentoText.innerText='Per il tuo compleanno, oltre a questo bellissimo messaggio di auguri, ti regaliamo una lezione di equitazione.'
    //  bentoBox.appendChild(bentoText);
    //  // Append the created divs to the textDiv
    //  textDiv.appendChild(auguriDiv);
    //  textDiv.appendChild(babiDiv);
    //  textDiv.appendChild(bentoBox);
    
   // }
-->