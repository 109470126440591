<template>
  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
body{
  background: linear-gradient(30deg, rgba(176,119,99,1) 15%,  rgba(188,144,97,1) 52%);;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

video{
  border-radius: 16px;
}
</style>
