<template>
    <div>
      <button @click="playSound">Cliccami 🐎</button>
      <audio ref="horseSound" :src="horseSoundSrc" preload="auto"></audio>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        horseSoundSrc: require('@/assets/horse-snort.wav') // Use Webpack's require to handle the asset
      }
    },
    methods: {
      playSound() {
        // Access the audio element and play it
        this.$refs.horseSound.play();
      }
    }
  }
  </script>
  
  <style scoped>
  button {
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    border-radius: 40px;
    border: thin solid black;
    background-color: #687259;
  }
  </style>